import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import Icon from "@mdi/react";
import {mdiPlusCircleOutline,  mdiDelete, mdiPencil, mdiSort} from "@mdi/js";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState} from "react";
import axios from "axios";
import Pagination from "react-js-pagination";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Application = () => {
  //const url = "http://localhost:8000/getApplicationData";
  const url = "https://ads.loopbots.in/getApplicationData";
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("_id");

  useEffect(() => {
    axios.get(url)
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const filteredData = data.filter((item) =>
    item.package_name.toLowerCase().includes(search.toLowerCase()) ||
    item._id.toLowerCase().includes(search.toLowerCase())
  );
  const sortedData = filteredData.slice().sort((a, b) => {
    const compareResult = a[sortBy].localeCompare(b[sortBy]);
    return sortOrder === "asc" ? compareResult : -compareResult;
  });
  const currentRecords = sortedData.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  // const currentRecords = filteredData
  // .slice(indexOfFirstRecord, indexOfLastRecord);

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this application user!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        //fetch(`http://localhost:8000/deleteApplicationData/${id}`, {
        fetch(`https://ads.loopbots.in/deleteApplicationData/${id}`, {
          method: 'DELETE'
        })
        .then(response => {
          if (response.ok) {
            setData(prevData => prevData.filter(item => item._id !== id));
            Swal.fire({
              title: 'Deleted!',
              text: 'application deleted successfully.',
              icon: 'success'
            });
          } else {
            throw new Error('Failed to delete application');
          }
        })
        .catch(error => {
          console.error(error);
        });
      }
    });
  };

  return (
    <>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <Sidebar />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
              style={{ height: "100vh" }}
            >
              <Header />
              <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div className="toolbar" id="kt_toolbar">
                  <div
                    id="kt_toolbar_container"
                    className="container-fluid d-flex flex-stack"
                  >
                    <div
                      data-kt-swapper="true"
                      data-kt-swapper-mode="prepend"
                      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                      className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                    >
                      <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                        Application
                        <span className="h-20px border-gray-200 border-start ms-3 mx-2" />
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="post d-flex flex-column-fluid" id="kt_post">
                  <div
                    id="kt_content_container"
                    className="container-xxl"
                    style={{ maxWidth: "100%" }}
                  >
                    <div className="g-5 gx-xxl-8">
                      <div className="card card-xxl-stretch">
                        <div className="card-header">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder text-dark">
                                Application
                            </span>
                          </h3>
                          <div className="card-toolbar">
                            <Link
                              to="/add_application"
                              className="btn btn-outline btn-outline-primary btn-active-light-primary btn-sm"
                            >
                              <Icon path={mdiPlusCircleOutline} size={1} /> Add Application
                            </Link>
                          </div>
                        </div>
                        <div className="card-header border-0 pt-6">
                          <div className="card-toolbar"></div>
                          <div className="card-title mx-0">
                            <div className="d-flex align-items-center position-relative">
                              <input
                                type="text"
                                data-kt-customer-table-filter="search"
                                className="form-control form-control-solid w-200px ps-15"
                                placeholder="Search..."
                                value={search}
                                onChange={handleSearch}
                              />
                            </div>
                          </div>
                        </div>
                        <ToastContainer />
                        <div className="card-body">
                          <div className="table-responsive">
                            <div className="my-1">
                              <table
                                id="kt_datatable_example_4"
                                className="table table-striped gy-5 gs-7 border rounded"
                              >
                                <thead>
                                  <tr className="fw-bolder fs-6 text-gray-800 px-7">
                                    <th className="min-w-50px pe-2" onClick={() => handleSort("_id")} style={{ cursor: "pointer" }}>ID
                                      {sortBy === "_id" && (
                                        <Icon path={mdiSort} size={1}
                                          style={{transform:sortOrder === "asc" ? "rotate(180deg)" : "rotate(0deg)",marginLeft: "5px",}}
                                        />
                                      )}
                                    </th>
                                    <th className="min-w-50px pe-2" onClick={() => handleSort("package_name")} style={{ cursor: "pointer" }}>Package Name
                                      {sortBy === "package_name" && (
                                        <Icon path={mdiSort} size={1}
                                          style={{transform:sortOrder === "asc" ? "rotate(180deg)" : "rotate(0deg)",marginLeft: "5px",}}
                                        />
                                      )}
                                    </th>
                                    <th className="min-w-50px pe-2" onClick={() => handleSort("os")} style={{ cursor: "pointer" }}>OS
                                      {sortBy === "os" && (
                                        <Icon path={mdiSort} size={1}
                                          style={{transform:sortOrder === "asc" ? "rotate(180deg)" : "rotate(0deg)",marginLeft: "5px",}}
                                        />
                                      )}
                                    </th>
                                    <th className="min-w-50px pe-2" onClick={() => handleSort("app_open_ad_provider")} style={{ cursor: "pointer" }}>App Open Ad Provider
                                      {sortBy === "app_open_ad_provider" && (
                                        <Icon path={mdiSort} size={1}
                                          style={{transform:sortOrder === "asc" ? "rotate(180deg)" : "rotate(0deg)",marginLeft: "5px",}}
                                        />
                                      )}
                                    </th>
                                    <th className="min-w-50px pe-2" onClick={() => handleSort("banner_ad_provider")} style={{ cursor: "pointer" }}>Banner Ad Provider
                                      {sortBy === "banner_ad_provider" && (
                                        <Icon path={mdiSort} size={1}
                                          style={{transform:sortOrder === "asc" ? "rotate(180deg)" : "rotate(0deg)",marginLeft: "5px",}}
                                        />
                                      )}
                                    </th>
                                    <th className="min-w-50px pe-2" onClick={() => handleSort("interstitial_ad_provider")} style={{ cursor: "pointer" }}>Interstitial Ad Provider
                                      {sortBy === "interstitial_ad_provider" && (
                                        <Icon path={mdiSort} size={1}
                                          style={{transform:sortOrder === "asc" ? "rotate(180deg)" : "rotate(0deg)",marginLeft: "5px",}}
                                        />
                                      )}
                                    </th>
                                    <th className="min-w-50px pe-2" onClick={() => handleSort("facebook_ad_provider")} style={{ cursor: "pointer" }}>Facebook Ad Provider
                                      {sortBy === "facebook_ad_provider" && (
                                        <Icon path={mdiSort} size={1}
                                          style={{transform:sortOrder === "asc" ? "rotate(180deg)" : "rotate(0deg)",marginLeft: "5px",}}
                                        />
                                      )}
                                    </th>
                                    <th className="min-w-50px pe-2" onClick={() => handleSort("status")} style={{ cursor: "pointer" }}>Status
                                      {sortBy === "status" && (
                                        <Icon path={mdiSort} size={1}
                                          style={{transform:sortOrder === "asc" ? "rotate(180deg)" : "rotate(0deg)",marginLeft: "5px",}}
                                        />
                                      )}
                                    </th>
                                    <th className="min-w-100px">Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {currentRecords.length === 0 ? (
                                  <tr style={{ textAlign: "center" }}><td colSpan={8}>No records found data</td></tr>
                                ) : (
                                  currentRecords.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item._id}</td>
                                      <td>{item.package_name}</td>
                                      <td>
                                        {item.os === "iOS" ? (
                                          <span className="badge badge-light-primary">iOS</span>
                                        ) : (
                                          <span className="badge badge-light-success">Android</span>
                                        )}
                                      </td>
                                      <td>{item.app_open_ad_provider === "admob" ? (
                                          <span className="">Admob</span>
                                        ) : (
                                          <span className=""></span>
                                        )}
                                      </td>
                                      <td>{item.banner_ad_provider === "app_lovin" ? (
                                          <span className="">App Lovin</span>
                                        ) : (
                                          <span className=""></span>
                                        )}
                                      </td>
                                      <td>{item.interstitial_ad_provider === "adsterra" ? (
                                          <span className="">Ad Sterra</span>
                                        ) : (
                                          <span className=""></span>
                                        )}
                                      </td>
                                      <td>{item.facebook_ad_provider === "facebook_data" ? (
                                          <span className="">Facebook</span>
                                        ) : (
                                          <span className=""></span>
                                        )}
                                      </td>
                                      <td>
                                        {item.status === "inactive" ? (
                                          <span className="badge badge-light-danger">Inactive</span>
                                        ) : (
                                          <span className="badge badge-light-success">Active</span>
                                        )}
                                      </td>
                                      <td>
                                        <Link to={`/edit_application/${item._id}`}
                                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                          <Icon path={mdiPencil} size={1} />
                                        </Link>
                                        <button 
                                          className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                                          onClick={() => handleDelete(item._id)}
                                          >
                                          <Icon path={mdiDelete} size={1} />
                                        </button>
                                      </td>
                                    </tr>
                                  )))} 
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="card-header border-0">
                            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-start mb-4">
                              <div className="dataTables_length d-flex align-items-center">
                                <label className="me-3">
                                  <select className="form-select form-select-sm form-select-solid"
                                    value={recordsPerPage}
                                    onChange={(e) =>
                                      setRecordsPerPage(Number(e.target.value))
                                    }
                                  >
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                  </select>
                                </label>
                                <div className="fs-6 fw-bold text-gray-700">
                                  Showing {indexOfFirstRecord + 1} to {Math.min(indexOfLastRecord, filteredData.length)} of {filteredData.length} entries
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-end">
                              <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={recordsPerPage}
                                totalItemsCount={filteredData.length}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                                innerClass="pagination"
                                activeClass="active"
                                disabledClass="disabled"
                                prevPageText="Previous"
                                nextPageText="Next"
                                hideFirstLastPages={true}
                                hideDisabled={true}
                                hideNavigation={false}
                                hideNavigationIcons={false}
                                hideFirstPageText={false}
                                hideLastPageText={false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
          <span className="svg-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={13}
                y={6}
                width={13}
                height={2}
                rx={1}
                transform="rotate(90 13 6)"
                fill="black"
              />
              <path
                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
      </div>
    </>
  );
};

export default Application;
