import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiListBoxOutline } from "@mdi/js"; 
import { mdiEyeOutline, mdiEyeOffOutline} from "@mdi/js";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';

const AddUsers = () => {
  const [username, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [application_ids, setApplicationIds] = useState([]);
  const [options, setOptions] = useState([]);
  const [appsign, setAppSign] = useState('1');
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(true);
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);
  
  useEffect(() => {
    //fetch('http://localhost:8000/getApplicationByIds')
    fetch('https://ads.loopbots.in/getApplicationByIds')
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success' && data.application) {
          const applicationOptions = data.application.map((application) => ({
            label: application.name,
            value: application.id,
          }));
          setOptions(applicationOptions);
        } else {
          console.error('Failed to fetch application name:', data.msg);
        }
      })
      .catch((error) => {
        console.error('Error fetching application name:', error);
      });
  }, []);

  const handleSelectChange = (selectedList) => {
    setApplicationIds(selectedList);
  };

  const handleUsernameChange = (event) => {
    const newUsername = event.target.value;
    setUserName(newUsername);
    //fetch(`http://localhost:8000/checkUsername/${newUsername}`)
    fetch(`https://ads.loopbots.in/checkUsername/${newUsername}`)
    .then((response) => response.json())
    .then((data) => {
      setIsUsernameAvailable(data.available);
    })
    .catch((error) => {
      console.error('Error checking username availability:', error);
    });
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    //fetch(`http://localhost:8000/checkEmail/${newEmail}`)
    fetch(`https://ads.loopbots.in/checkEmail/${newEmail}`)
    .then((response) => response.json())
    .then((data) => {
      setIsEmailAvailable(data.available);
    })
    .catch((error) => {
      console.error('Error checking email availability:', error);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm() && isUsernameAvailable && isEmailAvailable ) {
      const selectedApplicationIds = application_ids.map((item) => item.value);
      const userData = {
        username,
        email,
        password,
        appsign,
        application_ids: selectedApplicationIds,
      };
      //console.log('Selected Application IDs:', selectedApplicationIds);

      //fetch('http://localhost:8000/addUser', {
      fetch('https://ads.loopbots.in/addUser', {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then((data) => {
        toast.success('You have successfully added a new users!');
        navigate("/users");
      })
      .catch((error) => {
        toast.error('Error in added new users!');
        console.error('Error in added new users!', error);
      });
    }
  };
  const validateForm = () => {
    const errors = {};
    if (!username) {
      errors.username = 'User Name field is required';
    }
    if (!email) {
      errors.email = 'Email field is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid';
    }
    if (!password) {
      errors.password = 'Password field is required';
    } else if (password.length < 6) {
      errors.password = 'Password should be at least 6 characters long';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  return (
    <>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <Sidebar />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
              style={{ height: "100vh" }}
            >
              <Header />
              <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div className="toolbar" id="kt_toolbar">
                  <div
                    id="kt_toolbar_container"
                    className="container-fluid d-flex flex-stack"
                  >
                    <div
                      data-kt-swapper="true"
                      data-kt-swapper-mode="prepend"
                      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                      className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                    >
                      <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                        Add New User
                        <span className="h-20px border-gray-200 border-start ms-3 mx-2" />
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="post d-flex flex-column-fluid" id="kt_post">
                  <div id="kt_content_container" className="container-xxl" style={{maxWidth: "100%"}}>
                    <div className="g-5 gx-xxl-8">
                      <div className="card card-xxl-stretch">
                        <div className="card-header">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder text-dark">
                              Add New User
                            </span>
                          </h3>
                          <div className="card-toolbar">
                            <Link to="/users" className="btn btn-outline btn-outline-primary btn-active-light-primary btn-sm"><Icon path={mdiListBoxOutline} size={1} /> User List
                            </Link>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="py-0">
                            <div className="rounded border p-10">
                              <form className="form" onSubmit={handleSubmit}>
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                        User Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="User Name"
                                        name="username"
                                        value={username}
                                        //onChange={(e) => setUserName(e.target.value)}
                                        onChange={handleUsernameChange}                        
                                      />
                                      {errors.username && <span style={{ color: "red" }}>{errors.username}</span>}
                                      {(!isUsernameAvailable || (errors.username)) && (
                                        <span style={{ color: "red" }}>
                                          {isUsernameAvailable ? '' : 'Username is already taken. '}
                                          {errors.username}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                        Email
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Email"
                                        name="email"
                                        value={email}
                                        //onChange={(e) => setEmail(e.target.value)}
                                        onChange={handleEmailChange}
                                      />
                                      {!isEmailAvailable && <span style={{ color: "red" }}>Email is already registered.</span>}
                                      {errors.email && <span style={{ color: "red" }}>{errors.email}</span>}
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                        Password
                                      </label>
                                      <input
                                        type={showPassword ? "text" : "password"}
                                        className="form-control"
                                        placeholder="Password"
                                        name="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                      />
                                      <div
                                      className="position-absolute translate-middle-y end-0 me-20" style={{ marginTop: "-20px", marginLeft: "100%" }}
                                      onClick={() => setShowPassword(!showPassword)}
                                      >
                                        {showPassword ? (
                                          <Icon
                                            path={mdiEyeOutline}
                                            size={1}
                                            className="opacity-75"
                                          />
                                        ) : (
                                          <Icon
                                            path={mdiEyeOffOutline}
                                            size={1}
                                            className="opacity-75"
                                          />
                                        )}
                                      </div>
                                      {errors.password && <span style={{ color: "red" }}>{errors.password}</span>}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="mb-10">
                                      <label className="form-label">
                                          App Sign ?
                                      </label>
                                      <div className="form-check form-check-custom form-check-solid">
                                        <label className="form-check-label me-2">
                                          All
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="appsign"
                                          value="1"
                                          checked={appsign === '1'}
                                          onChange={(e) => setAppSign(e.target.value)}
                                        />
                                        <label className="form-check-label me-2">
                                          Selection
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="appsign"
                                          value="2"
                                          checked={appsign === '2'}
                                          onChange={(e) => setAppSign(e.target.value)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {appsign === '2' && (
                                    <div className="col-lg-6">
                                      <div className="mb-10">
                                        <label className="form-label">Select App Id</label>
                                        <Multiselect
                                          options={options}
                                          selectedValues={application_ids}
                                          onSelect={(selectedList, selectedItem) =>
                                            handleSelectChange([...application_ids, selectedItem])
                                          }
                                          onRemove={(selectedList, removedItem) =>
                                            handleSelectChange(application_ids.filter((item) => item !== removedItem))
                                          }
                                          displayValue="label"
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="card-footer d-flex justify-content-end py-6 px-0">
                                  <button
                                    type="submit"
                                    className="btn btn-outline btn-outline-primary btn-active-light-primary btn-sm me-2"
                                  >
                                    Save
                                  </button>
                                  <Link to="/users"
                                    type="reset"
                                    className="btn btn-outline btn-outline-danger btn-active-light-danger btn-sm"
                                  >
                                    Cancel
                                  </Link>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
          <span className="svg-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={13}
                y={6}
                width={13}
                height={2}
                rx={1}
                transform="rotate(90 13 6)"
                fill="black"
              />
              <path
                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
      </div>
    </>
  );
};

export default AddUsers;

//const [selectAll, setSelectAll] = useState(false);
//selectedValues={selectAll ? options : application_ids}
// useEffect(() => {
  //   fetch('http://localhost:8000/getApplicationByIds')
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.status === 'success' && data.application) {
  //         const applicationOptions = data.application.map((application) => ({
  //           label: application.name,
  //           value: application.id,
  //         }));
  //         setOptions([{ label: 'All Select', value: '__all__' }, ...applicationOptions]);
  //       } else {
  //         console.error('Failed to fetch application name:', data.msg);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching application name:', error);
  //     });
  // }, []);

  // const handleSelectChange = (selectedList) => {
  //   if (selectedList.some((item) => item.value === '__all__')) {
  //     setApplicationIds(options.filter((option) => option.value !== '__all__'));
  //     setSelectAll(true);
  //   } else {
  //     setApplicationIds(selectedList);
  //     setSelectAll(false);
  //   }
  // };