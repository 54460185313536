import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiListBoxOutline, mdiMinus, mdiPlus } from "@mdi/js"; 
import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddApplication = () => {
  const [package_name, setPackageName] = useState('');
  const [description, setDescription] = useState('');
  const [disable_banner_ad, setDisableBannerAd] = useState('active');
  const [disable_interstitial_ad, setDisableInterstitialAd] = useState('active');
  const [game_ad_provider, setGameAdProvider] = useState('');
  const [status, setStatus] = useState('active');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [os, setOS] = useState('');

  const handleSelectChange = (event) => {
    setOS(event.target.value);
  };

  const [click_count_for_interstitial_ad, setClickCountForInterstitialAd] = useState('3');
  const handleSelectChangeClickCount = (event) => {
    setClickCountForInterstitialAd(event.target.value);
  };

  const [prompt_rate_app_days, setPromptRateAppDays] = useState('');
  const handleSelectChangePromptRateAppDays = (event) => {
    setPromptRateAppDays(event.target.value);
  };

  const [prompt_rate_app_launch, setPromptRateAppLaunch] = useState('');
  const handleSelectChangePromptRateAppLaunch = (event) => {
    setPromptRateAppLaunch(event.target.value);
  };

  const [showAdditionalSection, setShowAdditionalSection] = useState(true);
  const [sections, setSections] = useState([]);
  const isFirstClickRef = useRef(true);

  const [app_open_ad_provider, setAppOpenAdProvider] = useState('');
  const handleSelectChangeAppOpen = (event) => {
    setAppOpenAdProvider(event.target.value);
  };

  useEffect(() => {
    if (isFirstClickRef.current) {
      const newSection = {
        id: sections.length + 1,
        showRemoveButton: false,
      };

      if (app_open_ad_provider === 'admob') {
        newSection = {
          ...newSection,
          admob_app_id: '',
          app_open_key: '',
          banner_key: '',
          interstitial_key: '',
          reward_key: '',
          native_key: '',
          rectangle_key: '',
          description: '',
          status: 'inactive',
        };
      } else if (app_open_ad_provider === 'app_lovin') {
        newSection = {
          ...newSection,
          sdk_key: '',
          report_key: '',
          event_key: '',
          ad_review_key: '',
          app_open_key: '',
          banner_key: '',
          interstitial_key: '',
          rectangle_key: '',
          description: '',
          status: 'inactive',
        }
      } else if (app_open_ad_provider === 'adsterra') {
        newSection = {
          ...newSection,
          ad_url: '',
          rectangle_key: '',
          description: '',
          status: 'inactive',
        };
      } else if (app_open_ad_provider === 'facebook_data') {
        newSection = {
          ...newSection,
          fb_app_id: '',
          app_open_key: '',
          banner_key: '',
          interstitial_key: '',
          rectangle_key: '',
          description: '',
          status: 'inactive',
        };
      }
      setSections([newSection]);
      isFirstClickRef.current = false;
    }
  }, [sections, app_open_ad_provider]);

  const addSection = () => {
    const newSection = {
      id: sections.length + 1,
      showRemoveButton: true,
    };
    
    setSections([...sections, newSection]);
  };
  
  const removeSection = (id) => {
    const updatedSections = sections.filter((section) => section.id !== id);
    setSections(updatedSections);
  };

  const handleSectionInputChange = (e, sectionId, fieldName) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        const fieldValue = e.target.value.trim();
        return {
          ...section,
          [fieldName]: fieldValue,
        };
      } else {
        return {
          ...section,
          status: 'inactive'
        };
      }
    });
    setSections(updatedSections);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const sectionData = app_open_ad_provider ? [] : sections.map((section) => {
        let additionalFields = {};
          if (app_open_ad_provider === 'admob') {
            additionalFields = {
              admob_app_id: section.admob_app_id,
              app_open_key: section.app_open_key,
              banner_key: section.banner_key,
              interstitial_key: section.interstitial_key,
              reward_key: section.reward_key,
              native_key: section.native_key,
              rectangle_key: section.rectangle_key,
              description: section.description,
              status: section.status,
            };
          } else if (app_open_ad_provider === 'app_lovin') {
            additionalFields = {
              sdk_key: section.sdk_key,
              report_key: section.report_key,
              event_key: section.event_key,
              ad_review_key: section.ad_review_key,
              app_open_key: section.app_open_key,
              banner_key: section.banner_key,
              rectangle_key: section.rectangle_key,
              description: section.description,
              interstitial_key: section.interstitial_key,
              status: section.status,
            };
          } else if (app_open_ad_provider === 'adsterra') {
            additionalFields = {
              ad_url: section.ad_url,
              rectangle_key: section.rectangle_key,
              status: section.status,
              description: section.description,
            };
          } else if (app_open_ad_provider === 'facebook_data') {
            additionalFields = {
              fb_app_id: section.fb_app_id,
              app_open_key: section.app_open_key,
              banner_key: section.banner_key,
              interstitial_key: section.interstitial_key,
              rectangle_key: section.rectangle_key,
              status: section.status,
              description: section.description,
            };
          }
          return {
            ...additionalFields,
          };
        });

      const applicationData = {
        package_name: package_name, 
        os: os,
        description: description,
        //app_open_ad_provider: "app_lovin",
        app_open_ad_provider: '',
        banner_ad_provider: '',
        interstitial_ad_provider: '',
        facebook_ad_provider: '',
        click_count_for_interstitial_ad: click_count_for_interstitial_ad,
        disable_banner_ad: disable_banner_ad,
        disable_interstitial_ad: disable_interstitial_ad,
        game_ad_provider: game_ad_provider,
        prompt_rate_app_days: prompt_rate_app_days,
        prompt_rate_app_launch: prompt_rate_app_launch,
        status: status,
        sections: sections,
      };
      sections.forEach((section) => {
        if (section.app_open_ad_provider === 'admob') {
          applicationData.app_open_ad_provider = section.app_open_ad_provider;
        }
        if (section.app_open_ad_provider === 'app_lovin') {
          applicationData.banner_ad_provider = section.app_open_ad_provider;
        }
        if (section.app_open_ad_provider === 'adsterra') {
          applicationData.interstitial_ad_provider = section.app_open_ad_provider;
        }
        if (section.app_open_ad_provider === 'facebook_data') {
          applicationData.facebook_ad_provider = section.app_open_ad_provider;
        }
      });
      console.log(applicationData);
      //fetch('http://localhost:8000/addApplicationData', {
      fetch('https://ads.loopbots.in/addApplicationData', {      
        method: 'POST',
        body: JSON.stringify(applicationData),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          toast.success('You have successfully added a new application data!');
          navigate("/application");
        })
        .catch((error) => {
          toast.error('Error in added new users!');
          console.error('Form submission failed:', error);
        });
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!package_name) {
      errors.package_name = 'Package Name field is required';
    }
    if (!os) {
      errors.os = 'OS field is required';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <Sidebar />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
              style={{ height: "100vh" }}
            >
              <Header />
              <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div className="toolbar" id="kt_toolbar">
                  <div
                    id="kt_toolbar_container"
                    className="container-fluid d-flex flex-stack"
                  >
                    <div
                      data-kt-swapper="true"
                      data-kt-swapper-mode="prepend"
                      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                      className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
                    >
                      <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                        Add New Application
                        <span className="h-20px border-gray-200 border-start ms-3 mx-2" />
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="post d-flex flex-column-fluid" id="kt_post">
                  <div id="kt_content_container" className="container-xxl" style={{maxWidth: "100%"}}>
                    <div className="g-5 gx-xxl-8">
                      <div className="card card-xxl-stretch">
                        <div className="card-header">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder text-dark">
                              Add New Application
                            </span>
                          </h3>
                          <div className="card-toolbar">
                            <Link to="/application" className="btn btn-outline btn-outline-primary btn-active-light-primary btn-sm"><Icon path={mdiListBoxOutline} size={1} /> Application List
                            </Link>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="py-0">
                            <div className="rounded border p-10">
                              <form className="form" onSubmit={handleSubmit}>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                        Package Name
                                      </label>
                                      <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Package Name"
                                          name="package_name"
                                          value={package_name}
                                          onChange={(e) => setPackageName(e.target.value)}
                                          //onBlur={validateForm}
                                      />
                                      {errors.package_name && <span style={{ color: "red" }}>{errors.package_name}</span>}
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="mb-10">
                                      <label className="required form-label">
                                        OS
                                      </label>
                                      <select 
                                        className="form-select"
                                        name="os"
                                        value={os}
                                        onChange={handleSelectChange}
                                        >
                                        <option value="">Select OS</option>
                                        <option value="android">Android</option>
                                        <option value="iOS">iOS</option>
                                      </select>
                                      {errors.os && <span style={{ color: "red" }}>{errors.os}</span>}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="mb-10">
                                      <label className="form-label">
                                        Description
                                      </label>
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        placeholder="Description"
                                        name="description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                      />
                                      {errors.description && <span style={{ color: "red" }}>{errors.description}</span>}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-4">
                                    <div className="mb-10">
                                      <label className="form-label">
                                          Game Ad Provider (URL)
                                      </label>
                                      <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Game Ad Provider"
                                          name="game_ad_provider"
                                          value={game_ad_provider}
                                          onChange={(e) => setGameAdProvider(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="mb-10">
                                      <label className="form-label">
                                        Prompt Rate App Days
                                      </label>
                                      <select 
                                        className="form-select"
                                        name="prompt_rate_app_days"
                                        value={prompt_rate_app_days}
                                        onChange={handleSelectChangePromptRateAppDays}
                                      >
                                        <option value="">Select Prompt Rate App Days</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="mb-10">
                                      <label className="form-label">
                                        Prompt Rate App Launch
                                      </label>
                                      <select 
                                        className="form-select"
                                        name="prompt_rate_app_launch"
                                        value={prompt_rate_app_launch}
                                        onChange={handleSelectChangePromptRateAppLaunch}
                                        >
                                        <option value="">Select Prompt Rate App Launch</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-3">
                                    <div className="mb-10">
                                      <label className="form-label">
                                        Interstitial Ad Click Count
                                      </label>
                                      <select 
                                        className="form-select"
                                        name="click_count_for_interstitial_ad"
                                        value={click_count_for_interstitial_ad}
                                        onChange={handleSelectChangeClickCount}
                                      >
                                        <option value="">Select Interstitial Ad Click Count</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                      </select>
                                    </div>
                                  </div>
                                  {/* <div className="col-lg-3">
                                    <div className="mb-10">
                                      <label className="form-label">
                                        App Open Ad
                                      </label>
                                      <div className="form-check form-switch py-4">
                                        <label className="form-check-label" htmlFor="disable_app_open_ad"></label>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="disable_app_open_ad"
                                          id="disable_app_open_ad"
                                          checked={disable_app_open_ad}
                                          //checked={disableAppOpenAd}
                                          //onChange={handleSwitchChange}
                                        />
                                      </div>
                                    </div>
                                  </div> */}
                                  <div className="col-lg-3">
                                    <div className="mb-10">
                                      <label className="form-label">
                                        Banner Ad
                                      </label>
                                      <div className="form-check form-check-custom form-check-solid">
                                        <label className="form-check-label me-2">
                                          Active
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="disable_banner_ad"
                                          value="active"
                                          checked={disable_banner_ad === 'active'}
                                          onChange={(e) => setDisableBannerAd(e.target.value)}
                                          />
                                          <label className="form-check-label me-2">
                                          Inactive
                                          </label>
                                          <input
                                          className="form-check-input"
                                          type="radio"
                                          name="disable_banner_ad"
                                          value="inactive"
                                          checked={disable_banner_ad === 'inactive'}
                                          onChange={(e) => setDisableBannerAd(e.target.value)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="mb-10">
                                      <label className="form-label">
                                          Interstitial Ad
                                      </label>
                                      <div className="form-check form-check-custom form-check-solid">
                                        <label className="form-check-label me-2">
                                        Active
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="disable_interstitial_ad"
                                          value="active"
                                          checked={disable_interstitial_ad === 'active'}
                                          onChange={(e) => setDisableInterstitialAd(e.target.value)}
                                        />
                                        <label className="form-check-label me-2">
                                        Inactive
                                        </label>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="disable_interstitial_ad"
                                          value="inactive"
                                          checked={disable_interstitial_ad === 'inactive'}
                                          onChange={(e) => setDisableInterstitialAd(e.target.value)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {showAdditionalSection && (
                                  <div className="">
                                    {/* <div className="card-body"> */}
                                      {/* <div className="rounded border p-5"> */}
                                      <div className="card card-custom shadow mb-5">
                                        {sections.map((section, index) => (
                                          <div key={section.id}>
                                            <div className="row">
                                              <div className="col-lg-12">
                                                <div className="card-header" style={{ backgroundColor: '#ccebff' }}>
                                                  <h3 className="card-title align-items-start flex-column">
                                                    <span className="card-label fw-bolder text-dark">
                                                      Section #
                                                    </span>
                                                  </h3>
                                                  <div className="card-toolbar">
                                                    {section.showRemoveButton && (
                                                    <button className="btn btn-icon btn-bg-danger btn-active-color-light btn-sm me-3" onClick={() => removeSection(section.id)}><Icon path={mdiMinus} size={1} /></button>
                                                    )}
                                                    {index === sections.length - 1 && (
                                                    <button className="btn btn-icon btn-bg-primary btn-active-color-primary btn-sm" onClick={addSection}>
                                                      <Icon path={mdiPlus} size={1} />
                                                    </button>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className="col-lg-12">
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">Ad Provider</label>
                                                  <select
                                                    className="form-select"
                                                    name="app_open_ad_provider"
                                                    value={section.app_open_ad_provider}
                                                    onChange={(e) => handleSectionInputChange(e, section.id, 'app_open_ad_provider')}
                                                  >
                                                    <option value="">Select Ad Provider</option>
                                                    <option value="admob">Admob</option>
                                                    <option value="app_lovin">App Lovin</option>
                                                    <option value="adsterra">Ad Sterra</option>
                                                    <option value="facebook_data">Facebook</option>
                                                  </select>
                                                </div>
                                              </div>
                                            </div>                                            
                                            {section.app_open_ad_provider === 'admob' && (
                                            <div className="row" style={{ marginTop: -80 }}>
                                              <div className="col-lg-4">
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">
                                                    Admob App Id
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Admob App Id"
                                                    name="admob_app_id"
                                                    value={section.admob_app_id}
                                                    onChange={(e) => handleSectionInputChange(e, section.id, 'admob_app_id')}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-lg-4">
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">
                                                    App Open Key
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="App Open Key"
                                                    name="app_open_key"
                                                    value={section.app_open_key}
                                                    onChange={(e) => handleSectionInputChange(e, section.id, 'app_open_key')}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-lg-4">
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">
                                                    Banner Key
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Banner Key"
                                                    name="banner_key"
                                                    value={section.banner_key}
                                                    onChange={(e) => handleSectionInputChange(e, section.id, 'banner_key')}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">
                                                    Interstitial Key
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Interstitial Key"
                                                    name="interstitial_key"
                                                    value={section.interstitial_key}
                                                    onChange={(e) => handleSectionInputChange(e, section.id, 'interstitial_key')}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">
                                                    Reward Key
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Reward Key"
                                                    name="reward_key"
                                                    value={section.reward_key}
                                                    onChange={(e) => handleSectionInputChange(e, section.id, 'reward_key')}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">
                                                    Native Key
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Native Key"
                                                    name="native_key"
                                                    value={section.native_key}
                                                    onChange={(e) => handleSectionInputChange(e, section.id, 'native_key')}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">
                                                    Rectangle Key
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Rectangle Key"
                                                    name="rectangle_key"
                                                    value={section.rectangle_key}
                                                    onChange={(e) => handleSectionInputChange(e, section.id, 'rectangle_key')}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">
                                                      Status
                                                  </label>
                                                  <div className="form-check form-check-custom form-check-solid">
                                                    <label className="form-check-label me-2">
                                                    Active
                                                    </label>
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name={`status_${section.id}`} 
                                                      value="active"
                                                      checked={section.status === 'active'}
                                                      onChange={(e) => handleSectionInputChange(e,section.id, 'status')}
                                                    />
                                                    <label className="form-check-label me-2">
                                                    Inactive
                                                    </label>
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name={`status_${section.id}`}
                                                      value="inactive"
                                                      checked={section.status === 'inactive'}
                                                      onChange={(e) => handleSectionInputChange(e,section.id, 'status')}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-lg-12" style={{ marginTop: -80 }}>
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">
                                                    Description
                                                  </label>
                                                  <textarea
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Description"
                                                    name="description"
                                                    value={section.description}
                                                    onChange={(e) => handleSectionInputChange(e, section.id, 'description')}
                                                  />
                                                </div>
                                              </div>                                              
                                            </div>
                                            )}
                                            {section.app_open_ad_provider === 'app_lovin' && (
                                              <div className="row" style={{ marginTop: -80 }}>
                                                <div className="col-lg-4">
                                                  <div className="mb-10 p-10">
                                                    <label className="form-label">
                                                      Sdk Key
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Sdk Key"
                                                      id={`sdk_key${section.id}`}
                                                      name={`sdk_key${index}`}
                                                      value={section.sdk_key || ''}
                                                      onChange={(e) => handleSectionInputChange(e, section.id, 'sdk_key')}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-lg-4">
                                                  <div className="mb-10 p-10">
                                                    <label className="form-label">
                                                      Report Key
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Report Key"
                                                      id={`report_key${section.id}`}
                                                      name={`report_key${index}`}
                                                      value={section.report_key || ''}
                                                      onChange={(e) => handleSectionInputChange(e, section.id, 'report_key')}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-lg-4">
                                                  <div className="mb-10 p-10">
                                                    <label className="form-label">
                                                      Event Key
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Event Key"
                                                      id={`event_key${section.id}`}
                                                      name={`event_key${index}`}
                                                      value={section.event_key || ''}
                                                      onChange={(e) => handleSectionInputChange(e, section.id, 'event_key')}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                  <div className="mb-10 p-10">
                                                    <label className="form-label">
                                                      Ad Review Key
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Ad Review Key"
                                                      id={`ad_review_key${section.id}`}
                                                      name={`ad_review_key${index}`}
                                                      value={section.ad_review_key || ''}
                                                      onChange={(e) => handleSectionInputChange(e, section.id, 'ad_review_key')}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                  <div className="mb-10 p-10">
                                                    <label className="form-label">
                                                      App Open Key
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="App Open Key"
                                                      id={`app_open_key${section.id}`}
                                                      name={`app_open_key${index}`}
                                                      value={section.app_open_key || ''}
                                                      onChange={(e) => handleSectionInputChange(e, section.id, 'app_open_key')}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                  <div className="mb-10 p-10">
                                                    <label className="form-label">
                                                      Banner Key
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Banner Key"
                                                      id={`banner_key${section.id}`}
                                                      name={`banner_key${index}`}
                                                      value={section.banner_key || ''}
                                                      onChange={(e) => handleSectionInputChange(e, section.id, 'banner_key')}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                  <div className="mb-10 p-10">
                                                    <label className="form-label">
                                                      Interstitial Key
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Interstitial Key"
                                                      id={`interstitial_key${section.id}`}
                                                      name={`interstitial_key${index}`}
                                                      value={section.interstitial_key || ''}
                                                      onChange={(e) => handleSectionInputChange(e, section.id, 'interstitial_key')}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                  <div className="mb-10 p-10">
                                                    <label className="form-label">
                                                      Rectangle Key
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Rectangle Key"
                                                      name="rectangle_key"
                                                      value={section.rectangle_key}
                                                      onChange={(e) => handleSectionInputChange(e, section.id, 'rectangle_key')}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                  <div className="mb-10 p-10">
                                                    <label className="form-label">
                                                        Status
                                                    </label>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                      <label className="form-check-label me-2">
                                                      Active
                                                      </label>
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={`status_${section.id}`} 
                                                        value="active"
                                                        checked={section.status === 'active'}
                                                        onChange={(e) => handleSectionInputChange(e,section.id, 'status')}
                                                      />
                                                      <label className="form-check-label me-2">
                                                      Inactive
                                                      </label>
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={`status_${section.id}`}
                                                        value="inactive"
                                                        checked={section.status === 'inactive'}
                                                        onChange={(e) => handleSectionInputChange(e,section.id, 'status')}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-lg-12" style={{ marginTop: -80 }}>
                                                  <div className="mb-10 p-10">
                                                    <label className="form-label">
                                                      Description
                                                    </label>
                                                    <textarea
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Description"
                                                      name="description"
                                                      value={section.description}
                                                      onChange={(e) => handleSectionInputChange(e, section.id, 'description')}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                            {section.app_open_ad_provider === 'adsterra' && (
                                            <div className="row" style={{ marginTop: -80 }}>
                                              <div className="col-lg-4">
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">
                                                    Ad URL
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Ad URL"
                                                    id={`ad_url${section.id}`}
                                                    name={`ad_url${index}`}
                                                    value={section.ad_url || ''}
                                                    onChange={(e) => handleSectionInputChange(e, section.id, 'ad_url')}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-lg-4">
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">
                                                    Rectangle Key
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Rectangle Key"
                                                    name="rectangle_key"
                                                    value={section.rectangle_key}
                                                    onChange={(e) => handleSectionInputChange(e, section.id, 'rectangle_key')}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-lg-4">
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">
                                                      Status
                                                  </label>
                                                  <div className="form-check form-check-custom form-check-solid">
                                                    <label className="form-check-label me-2">
                                                    Active
                                                    </label>
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name={`status_${section.id}`} 
                                                      value="active"
                                                      checked={section.status === 'active'}
                                                      onChange={(e) => handleSectionInputChange(e,section.id, 'status')}
                                                    />
                                                    <label className="form-check-label me-2">
                                                    Inactive
                                                    </label>
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name={`status_${section.id}`}
                                                      value="inactive"
                                                      checked={section.status === 'inactive'}
                                                      onChange={(e) => handleSectionInputChange(e,section.id, 'status')}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-lg-12" style={{ marginTop: -80 }}>
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">
                                                    Description
                                                  </label>
                                                  <textarea
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Description"
                                                    name="description"
                                                    value={section.description}
                                                    onChange={(e) => handleSectionInputChange(e, section.id, 'description')}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            )}
                                            {section.app_open_ad_provider === 'facebook_data' && (
                                            <div className="row" style={{ marginTop: -80 }}>
                                              <div className="col-lg-4">
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">
                                                    Fb App Id
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Fb App Id"
                                                    id={`fb_app_id${section.id}`}
                                                    name={`fb_app_id${index}`}
                                                    value={section.fb_app_id || ''}
                                                    onChange={(e) => handleSectionInputChange(e, section.id, 'fb_app_id')}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-lg-4">
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">
                                                    App Open Key
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="App Open Key"
                                                    id={`app_open_key${section.id}`}
                                                    name={`app_open_key${index}`}
                                                    value={section.app_open_key || ''}
                                                    onChange={(e) => handleSectionInputChange(e, section.id, 'app_open_key')}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-lg-4">
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">
                                                    Banner Key
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Banner Key"
                                                    id={`banner_key${section.id}`}
                                                    name={`banner_key${index}`}
                                                    value={section.banner_key || ''}
                                                    onChange={(e) => handleSectionInputChange(e, section.id, 'banner_key')}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">
                                                    Interstitial Key
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Interstitial Key"
                                                    id={`interstitial_key${section.id}`}
                                                    name={`interstitial_key${index}`}
                                                    value={section.interstitial_key || ''}
                                                    onChange={(e) => handleSectionInputChange(e, section.id, 'interstitial_key')}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">
                                                    Rectangle Key
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Rectangle Key"
                                                    id={`rectangle_key${section.id}`}
                                                    name={`rectangle_key${index}`}
                                                    value={section.rectangle_key || ''}
                                                    onChange={(e) => handleSectionInputChange(e, section.id, 'rectangle_key')}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-lg-4" style={{ marginTop: -80 }}>
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">
                                                      Status
                                                  </label>
                                                  <div className="form-check form-check-custom form-check-solid">
                                                    <label className="form-check-label me-2">
                                                    Active
                                                    </label>
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name={`status_${section.id}`} 
                                                      value="active"
                                                      checked={section.status === 'active'}
                                                      onChange={(e) => handleSectionInputChange(e,section.id, 'status')}
                                                    />
                                                    <label className="form-check-label me-2">
                                                    Inactive
                                                    </label>
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name={`status_${section.id}`}
                                                      value="inactive"
                                                      checked={section.status === 'inactive'}
                                                      onChange={(e) => handleSectionInputChange(e,section.id, 'status')}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-lg-12" style={{ marginTop: -80 }}>
                                                <div className="mb-10 p-10">
                                                  <label className="form-label">
                                                    Description
                                                  </label>
                                                  <textarea
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Description"
                                                    name="description"
                                                    value={section.description}
                                                    onChange={(e) => handleSectionInputChange(e, section.id, 'description')}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            )}
                                          </div>
                                        ))}
                                      </div>
                                  </div>
                                )}
                              <div className="row py-10">
                                <div className="col-lg-3">
                                  <div className="mb-10">
                                    <label className="form-label">
                                        Status
                                    </label>
                                    <div className="form-check form-check-custom form-check-solid">
                                      <label className="form-check-label me-2">
                                      Active
                                      </label>
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="status"
                                        value="active"
                                        checked={status === 'active'}
                                        onChange={(e) => setStatus(e.target.value)}
                                      />
                                      <label className="form-check-label me-2">
                                      Inactive
                                      </label>
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="status"
                                        value="inactive"
                                        checked={status === 'inactive'}
                                        onChange={(e) => setStatus(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div> 
                              <div className="card-footer d-flex justify-content-end py-6 px-0">
                                <button
                                  type="submit"
                                  className="btn btn-outline btn-outline-primary btn-active-light-primary btn-sm me-2"
                                >
                                  Save
                                </button>
                                <Link to="/application"
                                  type="reset"
                                  className="btn btn-outline btn-outline-danger btn-active-light-danger btn-sm"
                                >
                                  Cancel
                                </Link>
                              </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
          <span className="svg-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x={13}
                y={6}
                width={13}
                height={2}
                rx={1}
                transform="rotate(90 13 6)"
                fill="black"
              />
              <path
                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
      </div>
    </>
  );
};

export default AddApplication;
